









































































































































































import { InputSetups } from '@/mixins/input-setups'
import InviteLinkActionSetting from '@/components/InviteLinks/InviteLinkActionSetting.vue'
import {
  FieldsShowTo,
  InviteLinkApproveType,
  InviteLinkToCreate,
  InviteLinkToEdit
} from '@/includes/logic/InviteLinks/types'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { PickerFormat } from 'piramis-base-components/src/components/Picker/types'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import { UserActivationActionTypeEnum } from 'piramis-base-components/src/shared/modules/BotFeedback/ActivationAction'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    InviteLinkActionSetting,
  },
  data() {
    return {
      InviteLinkApproveType,
      PickerFormat,
      MediaEditorMode
    }
  },
})
export default class LinkPage extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  isLoading = false

  linkModel: InviteLinkToCreate | InviteLinkToEdit = {
    title: '',
    tags: [],
    config: {
      approve: InviteLinkApproveType.None,
      disable_auto_attack: false,
      expire: '',
      limit: 0,
      price: 0,
      message: [ {
        attachments: [],
        text: '',
        buttons: [],
        remove_previous: false,
        pin: false,
        disable_link_preview: false,
        disable_notify: false,

        protect_content: false,
        remove_after: 0,
        send_after: 0
      } ],
      user_accept_action: {
        type: UserActivationActionTypeEnum.Message,
        variants: []
      },
      user_reject_action: {
        type: UserActivationActionTypeEnum.Message,
        variants: []
      },
      user_captcha_success_action: {
        type: UserActivationActionTypeEnum.Message,
        variants: []
      },
      force_approve: false
    }
  }

  get cardTitle() {
    if (this.linkActionType === 'new') {
      return this.$t('link_list_create').toString()
    } else {
      return this.$t('link_list_edit').toString()
    }
  }

  get actionSettingFields(): FieldsShowTo {
    const linkActionTypeIsNotEdit = this.linkActionType !== 'edit'
    const linkApproveIsSet = this.linkModel.config.approve !== InviteLinkApproveType.None

    return {
      force_approve: linkActionTypeIsNotEdit ? linkApproveIsSet : true,
      message: linkActionTypeIsNotEdit ? linkApproveIsSet : true,
      user_captcha_success_action: linkActionTypeIsNotEdit ? linkApproveIsSet : true,
      user_accept_action: linkActionTypeIsNotEdit ? linkApproveIsSet : true,
      user_reject_action: linkActionTypeIsNotEdit ? linkApproveIsSet : true,
    }
  }

  get actionSettingFieldsReadonly(): FieldsShowTo {
    const linkActionTypeIsNotEdit = this.linkActionType === 'edit'

    return {
      approve: linkActionTypeIsNotEdit,
    }
  }

  get approveTypeOptions(): Array<SelectOptionData> {
    return Object.values(InviteLinkApproveType).map((type) => {
      return {
        label: this.$t(`invite_link_approve_type_${ type.toLowerCase() }`).toString(),
        value: type
      }
    })
  }

  get isSaveButtonDisabled() {
    const approve = this.linkModel.config.approve

    if (approve === InviteLinkApproveType.None) {
      return !this.linkModel.title
    } else {
      const message = this.linkModel.config.message

      return !(this.linkModel.title && message && message!.length && message[0].text.trim().length)
    }
  }

  get linkActionType() {
    return this.$route.params.actionType.toString() as 'new' | 'edit'
  }

  get linkKey() {
    return this.$route.query.key.toString()
  }

  goToRemovingUsers() {
    this.$router.push({
      name: 'Channel_protection',
      params: this.$route.params,
      query: {
        pressLink: this.linkKey
      }
    })
  }

  handleLinkSaveButtonClick() {
    const approve = this.linkModel.config.approve

    if (approve === InviteLinkApproveType.None) {
      delete this.linkModel.config.message
      delete this.linkModel.config.user_reject_action
      delete this.linkModel.config.user_accept_action
      delete this.linkModel.config.force_approve
      delete this.linkModel.config.user_captcha_success_action
    } else if (approve === InviteLinkApproveType.Auto) {
      delete this.linkModel.config.user_reject_action
    }

    if (this.linkActionType === 'new') {
      this.createInviteLink()
    }

    if (this.linkActionType === 'edit') {
      this.editInviteLink()
    }
  }

  editInviteLink() {
    this.$store.dispatch('editInviteLink', { link: this.linkModel, link_key: this.linkKey })
      .finally(() => this.gotoInviteLinksListPage())
  }

  createInviteLink() {
    this.$store.dispatch('createInviteLink', this.linkModel)
      .finally(() => this.gotoInviteLinksListPage())
  }

  gotoInviteLinksListPage() {
    this.$router.push({ name: 'invite_links_list' })
  }

  async created(): Promise<void> {
    this.isLoading = true

    // if (!this.$store.state.flowsState.flows) {
    //   await this.$store.dispatch('requestFlows')
    // }

    if (!this.$store.state.inviteLinksState.links) {
      await this.$store.dispatch('getInviteLinks')
    }

    if (this.linkActionType === 'edit' && this.linkKey) {
      const link = await this.$store.dispatch('getInviteLinkModel', this.linkKey)

      if (link) {
        this.linkModel = cloneDeep(link)
      }
    }

    this.isLoading = false
  }
}
